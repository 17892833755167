<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:single-expand="true"
		:expanded.sync="expanded"
		:search="search"
		:loading="loading"
		:page.sync="page"
		:items-per-page="pages"
		hide-default-footer
		item-key="id"
		show-expand
		class="elevation-1"
		loading-text="Cargando..."
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Liquidaciones</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-text-field 
					v-model="search" 
					append-icon="mdi-magnify" 
					label="Buscar"
				>
				</v-text-field>
				<v-col cols="6">
					<v-container class="max-width">
						<v-pagination
							v-model="page"
							:length="pageCount"
							circle
						></v-pagination>
					</v-container>
				</v-col>
				<v-spacer></v-spacer>
				<v-btn color="warning" dark class="mb-2" @click="iniciar">
					<v-icon>cached</v-icon>
				</v-btn>			
			</v-toolbar>
		</template>
		<template v-slot:no-data>
			No se encuentran liquidaciones
		</template>
		<template v-slot:expanded-item="{ headers, item }">
			<td :colspan="headers.length">
				<v-data-table
					dense
					:headers="itemsHeaders"
					:items="item.liquidated_services"
					class="elevation-1"
				>
				</v-data-table>
			</td>
		</template>
	</v-data-table>
</template>

<script>
import { ApiRoll  } from "./ApiRoll";

export default {
    
	name : "Liquidacion",

	data() {
		return{
			api: new ApiRoll(),
			loading: false,
			search:"",
			items: [],
			page:1,
			pageCount: 0,
			totalBills:0,
			expanded: [],
			singleExpand: false,
			pages:-1,
			headers:[
				{ text: "Empleado", value: "emp_description" },
				{ text: "Fecha Liquidación", value: "date" },
				{ text: "Inicio", value: "initial" },
				{ text: "Final", value: "end" },
				{ text: "Servicios Total", value: "serv_pay_employee" },
				{ text: "Pago Total", value: "pay_employee" },
			],
			itemsHeaders:[
				{ text: "Consecutivo Factura", value: "bill_description" },
				{ text: "Servicio", value: "ser_description" },
				{ text: "Comisión", value: "comission_pay" },
			],
		}
	},
	watch:{
		page(val) {
			this.iniciar(val)
		},
	},	
	methods: {
		async iniciar() {
			try {
				this.loading = true;
				let params = `enterprise=${localStorage.getItem("SucursalId")}&page=${this.page}`
				let r = await this.api.getLiquidacionesPaginator(params);
				this.items = r.results; 
				this.totalBills = r.count; 
				this.pages = this.items.length
				if (this.page === 1){
					this.pageCount = Math.ceil(this.totalBills / this.pages)
				}
			} catch (error) {
				alert(error);
				// this.mensaje(error,"Error","error")
				// this.msgError(error)
				this.$swal("Error", error.toString())
			} finally {
				this.loading = false;
			}
		},
	},
	created(){
		this.iniciar()
	},
}
</script>

<style scoped>
.styled-input {
  height: 40px;
}
</style>