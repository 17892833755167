<template>
    <v-app>
      <v-row>
        <v-col>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-1"
            :loading="loading"
            :items-per-page="pages"
            loading-text="Cargando..."
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Empleados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field 
                  v-model="search" 
                  append-icon="mdi-magnify" 
                  label="Buscar"
                >
                </v-text-field>
                <v-spacer></v-spacer>
                <v-btn color="warning" dark class="mb-2" @click="iniciar">
                  <v-icon>cached</v-icon>
                </v-btn>
                <v-dialog v-model="dialog" max-width="100%" dense persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon>add_box</v-icon></v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="2" >
                            <v-text-field v-model="editedItem.id" label="ID" disabled="" ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field 
                              v-model="editedItem.code" 
                              label="Código"
                              :rules="rules.in_req"  
                              color="purple darken-2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field 
                              v-model="editedItem.name" 
                              label="Nombres y Apellidos"
                              :rules="rules.in_req"
                              color="purple darken-2"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-autocomplete
                              v-model="editedItem.type_identification"
                              :items="types_doc"
                              label="Tipo"
                              item-text="text"
                              item-value="value"
                              color="purple darken-2"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <v-text-field 
                              v-model="editedItem.identification" 
                              label="Identificación"
                              :rules="rules.in_req"
                              color="purple darken-2"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field 
                              v-model="editedItem.address" 
                              label="Dirección"
                              :rules="rules.in_req"
                              color="purple darken-2"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field 
                              v-model="editedItem.phone" 
                              type="number"
                              :rules="rules.phoneRules"
                              :counter="10"
                              label="Celular"
                              color="purple darken-2"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.birthday"
                                  label="Fecha de cumpleaños"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.birthday"
                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                min="1950-01-01"
                                locale="es-co"
                                @change="save"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col>
                            <v-text-field 
                              v-model="editedItem.category"  
                              label="Categoria"
                              :rules="rules.in_req"
                              color="purple darken-2"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-autocomplete 
                              v-model="editedItem.enterprise" 
                              :items="itemsBranch"
                              label="Empresa"
                              item-text="name"
                              item-value="id"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <v-autocomplete 
                              v-model="editedItem.active" 
                              :items="booltypes"
                              label="Activo"
                              item-text="text"
                              item-value="value"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                      <v-btn color="pink accent-3" text :disabled="saving" @click="saveEmp">Guardar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.active="{ item }">
              <div v-if="item.active">
                <v-btn
                  class="ma-2"
                  color="success"
                  text
                >
                  <v-icon
                    dark
                    center
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  class="ma-2"
                  color="red"
                  text
                >
                  <v-icon
                    dark
                    center
                  >
                    mdi-cancel
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon color="danger" small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-app>
</template>

<script>

import { ApiRoll  } from "./ApiRoll";

export default {  
    name: "Empleados",

    data() {
      return {
        items: [],
        itemsBranch: [],
        model:null,
        api: new ApiRoll(),
        loading: false,
        saving: false,
        search: "",  
        editedIdex:-1,
        menu: false,
        pages:-1,
        headers: [
          { text: "ID", value: "id" },
          {
          text: "Código",
          align: "start",
          value: "code",
          },
          {text:'Nombre',value:'name'},
          {
            text: "Tipo",
            align: "start",
            value: "type_identification",
          },
          {
            text: "Identificación",
            align: "start",
            value: "identification",
          },
          {text:'Dirección',value:'address'},
          {text:'Celular',value:'phone',sortable:false},
          {text:'Cumpleaños',value:'birthday',sortable:false},
          {text:'Categoria',value:'category',sortable:false},
          {text:'Empresa',value:'ent_description',sortable:false},
          {text:'Activo',value:'active',sortable:false},
          {text:'Acciones',value:'actions',sortable:false},
        ],
        types_doc:[
          {text:"CEDULA", value:"CC"},
          {text:"TARJETA DE IDENTIDAD", value:"TI"},
          {text:"CEDULA DE EXTRANJERIA", value:"CE"},
          {text:"PASAPORTE", value:"PS"},
        ],
        editedItem:{
          id:-1,
          code:"",
          type_identification:{text:"CEDULA", value:"CC"},
          identification:"",
          name:"",
          address:"",
          phone:"",
          birthday:"",
          category:"",
          enterprise:{id:1},
          active:false,
        },
        defaultItem:{
          id:-1,
          code:"",
          type_identification:{text:"CEDULA", value:"CC"},
          identification:"",
          name:"",
          address:"",
          phone:"",
          birthday:"",
          category:"",
          enterprise:{id:1},
          active:false,
        },
        dialog:false,
        rules:{
          in_req: [val => (val || '').length > 0 || 'Requerido'],
          phoneRules: [val => (val || ''). length === 10 || 'Debe tener 10 numeros'],
        },
        booltypes:[
          {text:"SI", value:true},
          {text:"NO", value:false}
        ],
      }        
    },
    computed:{
        formTitle(){
          return (this.editedIdex === -1 ? "Nueva": "Editar") + " Empleado"
        },
    },
    watch:{
      dialog(val){
        val || this.close()
      }
    },
    methods: {
      async iniciar() {
        try {
          this.loading = true;
          let r = await this.api.getEmpleados();
          let ar = await this.api.getSucursales();
          this.items = r;     
          this.itemsBranch = ar;     
        } catch (error) {
            // alert(error);
            // this.mensaje(error,"Error","error")
            // this.msgError(error)
            this.$swal("Error", error.toString())
        } finally {
          this.loading = false;
        }
      },
      save (date) {
        this.$refs.menu.save(date)
      },
      async saveEmp(){
        const cp = this.editedItem;

        let idEnterprise = null
        let valueActive = null
        let valueIdent = null

        if (cp["enterprise"]["id"] !== undefined){
          idEnterprise=cp["enterprise"]["id"]
        }else{
          idEnterprise=cp["enterprise"]
        }

        if (cp["active"]["value"] !== undefined){
          valueActive=cp["active"]["value"]
        }else{
          valueActive=cp["active"]
        }

        if (cp["type_identification"]["value"] !== undefined){
          valueIdent=cp["type_identification"]["value"]
        }else{
          valueIdent=cp["type_identification"]
        }

        const obj = {
          "id":cp["id"],
          "code":cp["code"],
          "type_identification":valueIdent,
          "identification":cp["identification"],
          "name":cp["name"],
          "address":cp["address"],
          "phone":cp["phone"],
          "birthday":cp["birthday"],
          "category":cp["category"],
          "enterprise":idEnterprise,
          "active":valueActive,
        }
        console.log(obj)
        
        try {
          this.loading = true
          this.saving = true
          let r = await this.api.saveEmpleados(obj)
          if (!r.ok){
            this.$swal("Error", Object.values(r)[0][0],"error")
          }
          else{
            this.$swal("Guardado","Empleado Guardado Satisfactoriamente", "success")
            this.close()
            this.iniciar()
          }
          // this.mensaje("Guardado Satisfactoriamente")
        } catch (error) {
          alert(error)
          // this.mensaje(error,"Error","error")
        } finally{
          this.loading = false
          this.saving = false
        }
      },
      close(){
        this.dialog = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
      },
      editItem(item){
        this.editedIdex = this.items.indexOf(item)
        this.editedItem = Object.assign({},item)
        this.dialog = true
      },
      async deleteItem(item){
      this.$swal({
        title: '¿Está Seguro?',
        html: `Borrar Empleado <br> <b>${item.name} </b>`,
        type: 'warning',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si, Borrarlo!',
        cancelButtonText: 'No, Mantenerlo!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then(async(result) => {
        if(result.value){
          let r = await this.api.delEmpleados(item.id)
          if (r.status === 500){
            this.$swal("Error","No se puede borrar el empleado","error")
          }else{
            this.$swal("Borrado", "Se borro satisfactoriamente el empleado", "success")
            this.iniciar()
          }
        }
      })
      }
    },
    created() {
        this.iniciar();
    },
}
</script>

<style scoped>

</style>