<template>
	<v-app>
		<v-form>
			<v-container>
				<v-toolbar flat color="white">
					<v-toolbar-title>Empleado</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<v-autocomplete 
						v-model="model"
						:loading="loading"
						:items="items"
						:filter="customFilter"
						hint="Nombre"
						persistent-hint
						hide-no-data
						item-text="name"	
						label="Buscar"
						class="mx-4"
						clearable
						return-object
					>
						<template v-slot:selection="{ attr, on, item, selected }">
							<v-chip
								v-bind="attr"
								:input-value="selected"
								color="blue-grey"
								class="white--text"
								v-on="on"
							>
								<span v-text="item.identification"></span>
							</v-chip>
						</template>
					</v-autocomplete>
					<v-col>
						<v-menu
							ref="menu1"
							v-model="menu1"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="initialDate"
									label="Fecha Inicial"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="initialDate"
								:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
								min="2020-01-01"
								locale="es-co"
								@change="save1"
							></v-date-picker>
						</v-menu>
					</v-col>
						<v-col>
						<v-menu
							ref="menu2"
							v-model="menu2"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="finalDate"
									label="Fecha Final"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="finalDate"
								:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
								min="2020-01-01"
								locale="es-co"
								@change="save2"
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-spacer></v-spacer>
					<v-btn color="warning" dark class="mb-2" @click="iniciar">
						<v-icon>cached</v-icon>
					</v-btn>
				</v-toolbar>
				<v-row>
					<v-col
						cols="12"
						md="4"	
					>
						<v-text-field
							v-model="editedItem.name"
							label="Nombre"
							:readonly="!isEditingName"
						>
							<template v-slot:append-outer>
								<v-slide-x-reverse-transition
									mode="out-in"
								>
									<v-icon
										:key="`icon-${isEditingName}`"
										:color="isEditingName ? 'success' : 'info'"
										@click="isEditingName = !isEditingName"
										v-text="isEditingName ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
									></v-icon>
								</v-slide-x-reverse-transition>
							</template>
						</v-text-field>
					</v-col>
					<v-col
						cols="12"
						md="4"
					>
						<v-text-field
							v-model="editedItem.category"
							label="Categoria"
							:readonly="!isEditingCategory"
						>
							<template v-slot:append-outer>
									<v-slide-x-reverse-transition
										mode="out-in"
									>
										<v-icon
											:key="`icon-${isEditingCategory}`"
											:color="isEditingCategory ? 'success' : 'info'"
											@click="isEditingCategory = !isEditingCategory"
											v-text="isEditingCategory ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
										></v-icon>
									</v-slide-x-reverse-transition>
								</template>
							</v-text-field>
					</v-col>
					<v-col
						cols="12"
						md="4"
					>
						<v-text-field
							v-model="editedItem.address"
							label="Dirección"
							:readonly="!isEditingAddress"
						>
							<template v-slot:append-outer>
								<v-slide-x-reverse-transition
									mode="out-in"
								>
									<v-icon
										:key="`icon-${isEditingAddress}`"
										:color="isEditingAddress ? 'success' : 'info'"
										@click="isEditingAddress = !isEditingAddress"
										v-text="isEditingAddress ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
									></v-icon>
								</v-slide-x-reverse-transition>
							</template>
						</v-text-field>
					</v-col>
					<v-col
						cols="12"
						md="4"
					>
						<v-text-field
							v-model="editedItem.phone"
							label="Celular"
							:readonly="!isEditingPhone"
						>
							<template v-slot:append-outer>
								<v-slide-x-reverse-transition
									mode="out-in"
								>
									<v-icon
										:key="`icon-${isEditingPhone}`"
										:color="isEditingPhone ? 'success' : 'info'"
										@click="isEditingPhone = !isEditingPhone"
										v-text="isEditingPhone ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
									></v-icon>
								</v-slide-x-reverse-transition>
							</template>
						</v-text-field>
					</v-col>
					<v-col
						cols="2"
					>
						<v-text-field
							v-model="editedItem.birthday"
							label="Cumpleaños"
							readonly
						>
						</v-text-field>
						</v-col>
						<v-col
							cols="2"
						>
							<v-btn 
								color="primary" 
								dark 
								class="mt-3"
								@click="liquidar"
							>
								Liquidar
							</v-btn>
						</v-col>
					<v-col
						cols="12"
						md="4"
					>
					</v-col>
				</v-row>
			</v-container>
		</v-form> 
		<v-data-table
			dense
			:headers="headers"
			:items="itemsSettlement"
			:search="search"
			class="elevation-1"
			:loading="loading"
			loading-text="Cargando..."
		>
		<template v-slot:top>
			<v-toolbar flat color="white">
				<v-toolbar-title class="mx-8">Servicios</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-row>
					<v-col cols="4">
						<v-text-field 
							v-model="search" 
							append-icon="mdi-magnify" 
							label="Buscar"
						>
						</v-text-field>
					</v-col>
				</v-row>				
			</v-toolbar>
			<v-spacer></v-spacer>
		</template>
		<template v-slot:footer>
				<v-row dense>
					<div class="mt-6 mr-2 text-end"><strong>Total</strong></div>
					<v-col cols="2" class="ms-auto">
						<v-text-field 
							v-model="servicesPay"
							readonly
							label="Servicios"
						></v-text-field>
					</v-col> 
					<v-col cols="2">
						<v-text-field 
							v-model="totalPay"
							readonly
							label="Pago"
						></v-text-field> 
					
					</v-col>
				</v-row>
			</template>
			<template v-slot:no-data>
				Sin items para liquidar
			</template>
		</v-data-table>
		<v-row class="mt-1">
			<v-col cols="12" class="text-end">
				<v-btn
					class="ma-2 rounded-pill"
					tile
					color="success"
					:disabled="saving"
					@click="savePay"
				>
				<v-icon left>
					mdi-checkbox-marked-circle-outline
				</v-icon>
					Guardar
				</v-btn>
				<v-btn
					tile
					color="red"
					class="ma-2 mr-4 rounded-pill"
					@click="cancel"
				>
				<v-icon left>
					mdi-cancel
				</v-icon>
					Cancelar
				</v-btn>
			</v-col>
		</v-row>
		<br>
	</v-app>
</template>

<script>
import { ApiRoll  } from "./ApiRoll";

export default {
    
	name : "Liquidacion",

	data() {
		return{
			api: new ApiRoll(),
			loading: false,
			saving: false,
			search:"",
			items: [],
			itemsSettlement: [],
			itemsSave:[],
			totalPay:0,
			servicesPay:0,
			model:null,
			menu1: false,
			menu2: false,
			initialDate:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			finalDate:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			isEditingName: false,
			isEditingCategory: false,
			isEditingAddress: false,
			isEditingPhone: false,
			isEditingAdv: false,
			editedItem:{
				id:-1,
				code:"",
				type_identification:{text:"CEDULA", value:"CC"},
				identification:"",
				name:"",
				address:"",
				phone:"",
				birthday:"",
				category:"",
				enterprise:{id:1},
				active:false,
			},
			defaultItem:{
				id:-1,
				code:"",
				type_identification:{text:"CEDULA", value:"CC"},
				identification:"",
				name:"",
				address:"",
				phone:"",
				birthday:"",
				category:"",
				enterprise:{id:1},
				active:false,
			},
			headers:[
          { text: "Consecutivo Factura", value: "bill_id" },
          { text: "Item Factura", value: "item" },
					{ text: "Cantidad", value: "quantity" },
          { text: "Servicio", value: "service_desc" },
          { text: "Valor", value: "service_price" },
          { text: "Comisión", value: "comission_pay" },
          { text: "Fecha", value: "date" },
			],
		}
	},
	watch: {
		model (val) {
			this.itemsSettlement = []
			this.totalPay = 0
			this.servicesPay = 0
			this.itemsSave = []
			if (val != null){
				this.editedItem = Object.assign({},val)
			}else{
				this.editedItem = Object.assign({}, this.defaultItem)
			}
		}
	},	
	methods: {
		async iniciar() {
			try {
				this.loading = true;
				let r = await this.api.getEmpleados();
				this.items = r
			} catch (error) {
				alert(error);
				// this.mensaje(error,"Error","error")
				// this.msgError(error)
				this.$swal("Error", error.toString())
			} finally {
				this.loading = false;
			}
		},
		save1 (date) {
			this.$refs.menu1.save(date)
		},
		save2 (date) {
			this.$refs.menu2.save(date)
		},
		customFilter (item, queryText) {
			const textOne = item.name
			const searchText = queryText
			return textOne.indexOf(searchText) > -1 
		},
		async liquidar(){
			this.itemsSettlement = []
			this.itemsSave = []
			this.totalPay = 0
			this.servicesPay = 0
			let params = `i_date=${this.initialDate}&f_date=${this.finalDate}&enterprise=${localStorage.getItem("SucursalId")}`
			let ri =  await this.api.getItemsS(this.editedItem.id, params)
			this.itemsSettlement = ri
			let objItems = {}
			for(const item in this.itemsSettlement){
				this.totalPay += this.itemsSettlement[item]["comission_pay"]
				this.servicesPay += this.itemsSettlement[item]["quantity"]
				objItems["bill_item"] = this.itemsSettlement[item]["id"]
				objItems["service"] = this.itemsSettlement[item]["service"]
				objItems["comission_pay"] = this.itemsSettlement[item]["comission_pay"]
				this.itemsSave.push(objItems)
				objItems = {}
			}
		},
		cancel(){
			this.itemsSettlement = []
			this.model = null
			this.totalPay = 0
			this.servicesPay = 0
			this.itemsSave = []
		},
		async savePay(){

			let obj = {}
			obj["id"] = -1
			obj["employee"] = this.editedItem["id"]
			obj["initial"] = this.initialDate
			obj["end"] = this.finalDate
			obj["pay_employee"] = this.totalPay
			obj["serv_pay_employee"] = this.servicesPay
			obj["enterprise"] = localStorage.getItem("SucursalId")
			obj["liquidated_services"] = this.itemsSave

			try {
				this.loading = true
				this.saving=true;
				let r = await this.api.saveLiquidacion(obj)
				if (!r.ok){
					if ("liquidated_services" in r){
						this.$swal("Error",Object.values(r["liquidated_services"]["0"]).toString(),"error")
					}else{
						this.$swal("Error", Object.values(r)[0][0],"error")
					}
				}else{
					this.$swal("Guardada","Liquidación Guardada Satisfactoriamente", "success")
					this.cancel()
					this.iniciar()
				}
				
			// this.mensaje("Guardado Satisfactoriamente")
			} catch (error) {
				alert(error)
			// this.mensaje(error,"Error","error")
			} finally{
				this.loading = false
				this.saving=false;
			}
		},
	},
	created(){
		this.iniciar()
	},
}
</script>

<style scoped>
.styled-input {
  height: 40px;
}
</style>