import { render, staticRenderFns } from "./SettlementList.vue?vue&type=template&id=649885b4&scoped=true&"
import script from "./SettlementList.vue?vue&type=script&lang=js&"
export * from "./SettlementList.vue?vue&type=script&lang=js&"
import style0 from "./SettlementList.vue?vue&type=style&index=0&id=649885b4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649885b4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCol,VContainer,VDataTable,VDivider,VIcon,VPagination,VSpacer,VTextField,VToolbar,VToolbarTitle})
